import camelCase from './utils/camelCase';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import AOS from 'aos';
import matchHeight from 'jquery-match-height';

const checkForElement = element => {
  if (typeof element != 'undefined') {
    return element;
  }
  return false;
};

document.addEventListener('DOMContentLoaded', () => {
  gsap.registerPlugin(ScrollTrigger);

  // *******
  // AOS stuff
  // *******

  // init AOS
  var aosOffset = 150,
  		aosDuration = 400;
  if ($('#mobile-tester').is(':visible')) {
    aosOffset = -50;
    aosDuration = 450;
  }

  AOS.init({
    duration: aosDuration,
    easing: 'ease-out-cubic',
    startEvent: 'load',
    offset: aosOffset,
  });

  // Match Height Helper Class
	$('.match-height-row').matchHeight({
		byRow: true,
		property: 'min-height',
	});
	$('.match-height').matchHeight({
		byRow: false,
		property: 'min-height',
	});

  // ***********
  // block-specific file calls
  // ***********

  const blocks = document.querySelectorAll('.husl-block');
  if (!blocks.length) return;

  var blocksArray = [];
  blocks.forEach(async module => {
    let name = checkForElement(module.dataset.block);
    if (name) {
      name = camelCase(name);
      if ($.inArray(name, blocksArray) == -1) {
        blocksArray.push(name);
      }
    } else {
      console.error('No block name found');
      return;
    }
  });

  if (blocksArray.length) {
    blocksArray.forEach(async name => {
      try {
        const { Block } = await import(
          /* webpackChunkName: 'block' */ `./blocks/${name}`
        );
        const block = new Block(name);
        block.init();
      } catch (error) {
        console.log(error);
        console.warn(
          `You're missing the js file for the ${name} block. \n Create the missing file inside the blocks folder: nylon/assets/js/blocks/${name}.js`
        );
      }
    });
  }

  // **********
  // nav sticky style stuff
  // **********

  // function for adding header sticky class
  function addStickyClass(scroll) {
  	if (scroll >= 50) {
      $('#masthead').addClass('sticked');
    } else {
      $('#masthead').removeClass('sticked');
    }
  }

  // check for header position on page load
  addStickyClass($(window).scrollTop());
  // check for header position on page scroll
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    addStickyClass(scroll);
  });

  // ******
  // nav / mega nav stuff
  // ******

  $('.mobile-trigger').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('.logo').toggleClass('active-menu');
    $('.mobile-nav').slideToggle();
  });

  let subLvlWidth = $('.mobile-nav').outerWidth();
  console.log(subLvlWidth);

  gsap.to('.sub-lvl-menu', { x: subLvlWidth, autoAlpha: 0 });

  $('.back-menu a').on('click', function (e) {
    e.preventDefault();
    gsap.to('.sub-lvl-menu', { x: subLvlWidth, autoAlpha: 0 });
  });

  $('.back-sub-menu a').on('click', function (e) {
    e.preventDefault();
    gsap.to('.sub-sub-lvl-menu', { x: subLvlWidth, autoAlpha: 0 });
  });

  $('.has-sub-menu, .has-mega-menu')
    .find('> a')
    .stop(true, true)
    .on('click', function (e) {
      e.preventDefault();
      let target = $(this).data('target');
      gsap.to(target, { x: 0, autoAlpha: 1 });
    });
});
